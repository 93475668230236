export const moscap = [
  {
    id: 1004,
    ques: 'IPI код гэж юу вэ?',
    ans: 'IPI код нь дуу зохиогчид болон нийтлэгчдийг олон улсын эрх эзэмшигч гэж ялгахын тулд CISAC-ийн гишүүн зохиолч, хөгжмийн зохиолч, нийтлэгчдийн нийгэмлэгүүдээс (PRO) олгодог 9 - 11 оронтой код юм. ',
  },
  {
    id: 1001,
    ques: 'MOSCAP гэж юу вэ ?',
    ans: 'Монголын зохиолч, хөгжмийн зохиолч, нийтлэгчдийн нийгэмлэг  (Mongolian Society of Composers, Authors & Publishers) MOSCAP нь “Зохиогчийн эрх болон түүнд хамаарах эрхийн тухай” хуулийн 27-р зүйлийн дагуу 2011 онд байгуулагдсан, хөгжмийн үгтэй болон үггүй бүтээлийн эрх эзэмшигчийн бүтээл ашиглах  онцгой эрхийг хамгаалдаг Монголын улсын  хамтын удирдлагын байгууллага юм.',
  },
  {
    id: 1002,
    ques: 'MOSCAP юу хийдэг вэ ?',
    ans: 'MOSCAP нь монголын дуу хөгжим бүтээгчдийн оюуны өмч, бүтээлийн зохиогчийн онцгой эрхийг олон улс, үндэсний хэмжээнд хамгаалах орчин үеийн тогтолцоог бүрдүүлж уран бүтээлч хүн уран бүтээлийнхээ үр шимийг хүртэж амьдрах бололцоог бүрдүүлэхийн төлөө ажиллаж байна.',
  },
  // {
  //   id: 1003,
  //   ques: 'Манай хаяг ? ',
  //   ans: 'Улаанбаатар хот Чингэлтэй дүүрэг, Жигжиджавын гудамж II байр 204 тоот Шуудангийн хаяг: Монгол улс, Улаанбаатар-15160 Төв шуудан, ш/х-327',
  // },
];
