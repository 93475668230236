import mnMessages from '../locales/mn_MN.json';
import mnMn from 'antd/lib/locale/mn_MN';

const mnLang = {
  messages: {
    ...mnMessages,
  },
  antLocale: mnMn,
  locale: 'mn-MN',
};
export default mnLang;
